<!--
 * @Author: CL
 * @Date: 2021-06-11 10:47:41
 * @LastEditTime: 2021-06-23 09:46:09
 * @Description: 首页
 * 
 * 1. 需要监听滚轮事件
-->

<template>
  <div class="home-page" @mousewheel="handleWheel" v-loading="loading">
    <!-- 轮播图片组件 -->
    <ul 
      class="banner-wrap" 
      ref="banner" 
      :style="{ marginTop }"
      @transitionend="handleTransitionEnd"
    >
      <li v-for="item in bannerList" :key="item.id">
        <Carouseitem :info="item"/>
      </li>
    </ul>
    <div 
      class="icon-up icon" 
      v-show="active > 0"
      @click="switchTo(active - 1)"
    >
      <IconFont type="topArrow" :size="30"/>
    </div>
    <div 
      class="icon-down icon" 
      v-show="active != bannerList.length - 1"
      @click="switchTo(active + 1)"
    >
      <IconFont type="downArrow" :size="30"/>
    </div>
    <ul class="points-wrap">
      <li 
        v-for="(item,index) in bannerList" 
        :key="item.id"
        :class="active == index? 'active' : ''"
        @click="switchTo(index)"
      ></li>
    </ul>
    <GoHome />
  </div>
</template>

<script>
import IconFont from '@/components/IconFont';
import Carouseitem from './components/CarouseItem.vue';
import GoHome from '@/components/GoHome';
import { mapState } from 'vuex';

export default {
  data(){
    return {
      active: 0,
      clientHeight: 0,
      isScroll: false,    //是否在滚动,
    }
  },

  created(){
    //请求轮播数据
    this.$store.dispatch('banner/queryBanner');
  },

  mounted() {
    this.clientHeight = this.$refs['banner'].clientHeight;

    //需要监听浏览器窗口尺寸的变化
    window.addEventListener('resize', this.handleResize);
  },

  /**
   * 组件销毁时，移除resize事件的监听
   */
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    ...mapState('banner', ['bannerList', 'loading']),
    
    marginTop(){
      return - this.active * this.clientHeight + 'px';
    }
  },

  methods: {
    /**
     * 切换图片
     * index: Number,  切换到第几张
     **/
    switchTo(index){
      this.active = index;
    },

    /**
     * 监听滚轮事件
     * 1. 滚轮向下滚动，图片下划
     * 2. 滚轮向上滚动，图片上划
     */
    handleWheel(e){
      if(this.isScroll) return;
      if(e.deltaY >= 125 && this.active + 1 <= this.bannerList.length - 1){
        this.active = ++this.active;
        this.isScroll = true; 
      }
      if(e.deltaY <= -125 && this.active > 0){
        this.active = --this.active;
        this.isScroll = true;
      }
    },

    /**
     * 监听过渡动画结束
     * 1. 动画结束需要把是否在滚动的状态设为false
     */
    handleTransitionEnd(){
      this.isScroll = false;
    },

    /**
     * 监听窗口尺寸变化的事件
     */
    handleResize(){
      this.clientHeight = this.$refs['banner'].clientHeight;
    }
  },

  components: {
    Carouseitem,
    IconFont,
    GoHome
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';
@import '~@/styles/var.less';

.home-page{
  height: 100%;
  position: relative;
  overflow: hidden;

  .banner-wrap{
    width: 100%;
    height: 100%;
    transition: all 1s;
    > li{
      width: 100%;
      height: 100%;
    }
  }

  .icon{
    .self-center();
    transform: translate(-50%, 0);
    top: auto;
    color: @info;
    cursor: pointer;

    &.icon-up{
      top: 20px;
      animation: up 2s infinite;
    }

    &.icon-down{
      bottom: 20px;
      animation: down 2s infinite;
    }
  }

  .points-wrap{
    .self-center();
    left: auto;
    right: 15px;

    > li{
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: @info;
      margin: 10px 0;
      cursor: pointer;

      &.active{
        background: @primary;
        border: none;
      }
    }
  }
}

@dis: 5px;

@keyframes up{
  0%{
    transform: translate(-50%, @dis);
  }
  50%{
    transform: translate(-50%, -@dis);
  }
  100%{
    transform: translate(-50%, @dis);
  }
}

@keyframes down{
  0%{
    transform: translate(-50%, -@dis);
  }
  50%{
    transform: translate(-50%, @dis);
  }
  100%{
    transform: translate(-50%, -@dis);
  }
}
</style>

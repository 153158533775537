<!--
 * @Author: CL
 * @Date: 2021-06-04 13:59:56
 * @LastEditTime: 2021-06-21 15:56:26
 * @Description: 图片加载组件
 * 有些图片可能会很大，加载的比较慢，所以会先加载占位图片
 * 1. origin: 原图的资源路径
 * 2. placeholder: 占位图的资源路径
 * 3. duration: 经过到长时间从占位图过渡到原图,单位是毫秒
 * 4. load事件， 原图加载完成之后要干什么，抛出去
-->

<template>
  <div class="image-load-contain">
    <img v-if="!everyThingDone" :src="placeHolder" class="place-holder">
    <img
      :style="{ opacity: isLoad, transition: `${duration}ms` }" 
      :src="origin" 
      @load="handleLoad" 
      class="origin"
    >
  </div>
</template>

<script>
export default {
  data(){
    return {
      everyThingDone: false,    //所有的事件都完成了
      haveLoad: false,    //原图是否加载完成
    }
  },

  computed: {
    /**
     * 原图加载完成透明度为1，没有加载完成透明度为0
     */
    isLoad(){
      return this.haveLoad? 1 : 0;
    }
  },

  props: {
    origin: {
      type: String,
      required: true
    },

    placeHolder: {
      type: String,
      required: true
    },

    duration: {
      type: Number,
      default: 500
    }
  },

  methods: {
    /**
     * 原图片加载完毕触发
     */
    handleLoad(){
      this.haveLoad = true;

      //当所有的事情都完成之后，就把占位图删掉
      setTimeout(() => {
        this.everyThingDone = true;
        this.$emit('load');
      }, this.duration)
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.image-load-contain{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  img{
    .self-fill();
    object-fit: cover;

    &.place-holder{
      filter: blur(2vw);
    }
  }
}
</style>

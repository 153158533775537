<!--
 * @Author: CL
 * @Date: 2021-06-12 23:52:51
 * @LastEditTime: 2021-06-22 11:08:04
 * @Description: 首页的图片轮播组件
 *
 * 1. 难点就是怎么让文字的宽度从 0 - auto 有过渡效果？
 *    如果直接写的话，是没有任何过渡效果的
 *    先要获取到这两个div的宽度， 然后在把它的宽度设为0， 然后在过渡到实际的宽度
 *
 * 2. 第二个难点就是怎么让图片有抖动的效果？
 *    得到外层容器的宽高，内层图片的宽高， 计算得出他们的宽高差
 *    在得到鼠标的位置， 先考虑极端情况，鼠标在最右端的时候， 图片的left：-宽差值， top: -高差值
 *    所以  left / mouseX = -宽差值 / 外层容器的宽度
 *    left = -宽差值 / 外层容器的宽度 * mouseX
 *   
 * 3. transform在性能方面要比left和top要好
-->

<template>
  <div 
    class="carouseitem-contain" 
    ref="carouse-contain"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
  >
    <div class="img-wrap" :style="imgPosition" ref="img-wrap">
      <ImageLoad 
        :origin="info.self? info.bigImg : (baseURL + info.bigImg)" 
        :placeHolder="info.self? info.smallImg : (baseURL + info.smallImg)"
        :duration="2000"
        @load="handleLoad"
      />
    </div>
    <div class="text-first" ref="title">{{ info.title }}</div>
    <div class="text-second" ref="desc">{{ info.subTitle }}</div>
  </div>
</template>

<script>
import ImageLoad from '@/components/ImageLoad';
import { mapState } from 'vuex';

export default {
  data(){
    return {
      titleWidth: 0,
      descWidth: 0,
      imgContain: null,    //外层容器的宽高
      imgWrap: null,       //内层图片的宽高
      mouseX: 0,           //鼠标离容器的横坐标
      mouseY: 0,           //鼠标离容器的纵坐标
    }
  },

  computed: {
    ...mapState(['baseURL']),

    imgPosition(){
      //先得到外层容器和内层容器的宽高差
      if(!this.imgContain || !this.imgWrap) return {};
      const extraX = this.imgWrap.width - this.imgContain.width;
      const extraY = this.imgWrap.height - this.imgContain.height;
      const left = (-extraX / this.imgContain.width) * this.mouseX;
      const top = (-extraY / this.imgContain.height) * this.mouseY;
      return {
        transform: `translate(${left}px, ${top}px)`
      }
    },

    /**
     * 一开始就让鼠标的位置居中
     */
    mouseCenter(){
      return {
        x: this.imgContain.width / 2,
        y: this.imgContain.height / 2
      }
    }
  },

  methods: {
    /**
     * 以动画的形式展示文字
     */
    showText(){
      this.$refs['title'].style.opacity = 1;
      this.$refs['title'].style.width = 0;
      this.$refs['title'].clientWidth;   //触发重绘，强制刷新
      this.$refs['title'].style.transition = '1s';
      this.$refs['title'].style.width =  this.titleWidth + 'px';

      this.$refs['desc'].style.opacity = 1;
      this.$refs['desc'].style.width = 0;
      this.$refs['desc'].clientWidth;   //触发重绘，强制刷新
      this.$refs['desc'].style.transition = '1s 1s';
      this.$refs['desc'].style.width =  this.descWidth + 'px';
    },

    /**
     * 图片加载完成，加载文字
     */
    handleLoad(){
      this.showText();
    },

    /**
     * 监听鼠标移动事件
     */
    handleMouseMove(e){
      //得到鼠标离视口的距离
      const mouseDisX = e.clientX;
      const mouseDisY = e.clientY;
      //得到外层容器离视口的距离
      const containDisX = this.$refs['carouse-contain'].getBoundingClientRect().left;
      const containDisY = this.$refs['carouse-contain'].getBoundingClientRect().top;
      this.mouseX = mouseDisX - containDisX;
      this.mouseY = mouseDisY - containDisY;
    },

    /**
     * 监听鼠标移出事件，再把鼠标鼠标的位置移到中心
     */
    handleMouseLeave(){
      this.mouseX = this.mouseCenter.x;
      this.mouseY = this.mouseCenter.y;
    },

    /**
     * 设置尺寸
     */
    setSize(){
      //得到图片外层容器和图片的宽度和高度
      this.imgContain = {
        width: this.$refs['carouse-contain'].clientWidth,
        height: this.$refs['carouse-contain'].clientHeight
      }
      this.imgWrap = {
        width: this.$refs['img-wrap'].clientWidth,
        height: this.$refs['img-wrap'].clientHeight
      }
    }
  },

  props: ['info'],

  mounted(){
    this.titleWidth = this.$refs['title'].clientWidth;
    this.descWidth = this.$refs['desc'].clientWidth;
    this.setSize();
    this.mouseX = this.mouseCenter.x;
    this.mouseY = this.mouseCenter.y;

    window.addEventListener('resize', this.setSize);
  },

  destroyed() {
    window.removeEventListener('resize', this.setSize);
  },

  components: {
    ImageLoad
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/var.less';

.carouseitem-contain{
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .img-wrap{
    width: 110%;
    height: 110%;
    position: absolute;
    transition: 0.5s;
  }

  .text-first, .text-second{
    position: absolute;
    top: 50%;
    left: 100px;
    color: white;
    text-shadow: 2px 0 0 rgba(0, 0, 0, 0.5), 0 2px 0 rgba(0, 0, 0, 0.5) 
    ,-2px 0 0 rgba(0, 0, 0, 0.5)
    ,0 -2px 0 rgba(0, 0, 0, 0.5);
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
  }

  .text-first{
    font-size: 3em;
  }

  .text-second{
    font-size: 1.8em;
    top: calc(60% + 30px);
    color: lighten(@info, 20%);
  }
}
</style>

